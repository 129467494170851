import $ from 'jquery';

import Swiper, {Navigation, Autoplay,Pagination} from 'swiper';

import 'swiper/swiper.scss';  
import 'swiper/modules/pagination/pagination.scss'; 
import 'swiper/modules/navigation/navigation.scss';   


import './../../scss/blocks/block-banner2.scss';

$(document).ready(function() {
    var slides = document.getElementsByClassName("banner2-swiper");

    for (var i = 0; i < slides.length; i++) {
      //Distribute(slides.item(i));
      var banner2swiper = new Swiper(slides.item(i), {
        modules: [Navigation,Pagination, Autoplay],
        spaceBetween: 10,
        loop: true,
        slidesPerView: 1,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        navigation: {
          nextEl: slides.item(i).getElementsByClassName('banner2-swiper-button-next').item(0),
          prevEl: slides.item(i).getElementsByClassName('banner2-swiper-button-prev').item(0),
        },
        pagination: {
          el: slides.item(i).getElementsByClassName('banner2-swiper-pagination').item(0),
          type: 'bullets',
        }
      });  
    }
  
})